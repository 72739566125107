import VHelp from '@components/help/help.vue';
import Help from '@components/account/dialogs/help/help.vue';

import { create } from 'vue-modal-dialogs';

import { throttle } from 'lodash';

export default {
  name: 'v-footer',
  props: {
    collapse: {
      type: Boolean,
      default: true
    }
  },
  components: { VHelp },
  mounted: function(){
    if(this.collapse){
      window.onscroll = throttle(() => {
        this.bottom = (window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 50;
      }, 100);
    }

    // If user has not seen the tutorial yet, show it immediately
    // Observe the values of both config from db and cookie, just in case
    // an ajax request to update "guided" flag failed previously
    // aka - redundancy

    if(this.isLoggedIn() && !this.config.user.guided && !this.getState('tutorial:shown', 0)){
      this.onClickHelp();
    }
  },
  methods: {
    onClickHelp: async function(){
      this.dispatch('help', { event_category: 'engagement', event_label: 'how_to', value: this.user.id || 0 });
      let help = create(Help);
      await help().transition();
    }
  },
  data: function(){
    return {
      bottom: this.collapse ? false : true
    };
  }
};
