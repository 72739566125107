import Flash from '@components/flash/flash.vue';

import Showdown from 'showdown/dist/showdown.min';

import { mapState, mapActions, mapGetters } from 'vuex';

Showdown.extension('targetlink', function(){
  return [{
    type: 'html',
    regex: /(<a[^>]*)>(.*?)<\/a>/g,
    replace: '$1 target="_blank">$2</a>'
  }];
});

const MotherboardPlugin = {
  install: function(Vue, options){
    Vue.mixin({
      watch: {
        dialogs: function(dialogs){
          $(document.body).toggleClass('no-overflow', Object.keys(dialogs).length > 0);
        }
      },
      methods: {
        flashAt: function(container){
          if($(container).find('.v-flash').length){
            return $(container).find('.v-flash').get(0).__vue__;
          }else if($('.v-flash').length){
            return $('.v-flash').get(0).__vue__;
          }
          var ComponentClass = Vue.extend(Flash);
          return new ComponentClass();
        },
        getAuthToken: function(){
          return $('meta[name="csrf-token"]').attr('content');
        },
        toHtml: function(text, truncate){
          let converter = new Showdown.Converter({
            tables: true,
            extensions: ['targetlink']
          });

          if(truncate){
            text = text.replace(/\!\[.*\]\([\/A-Z0-9_\-\.:]+\)/ig, '')
            text = text.substr(0, 500)
          }
          return converter.makeHtml(text);
        },
        setState: function(key, value){
          window.localStorage.setItem(key, value.toString());
        },
        getState: function(key, if_null){
          if(if_null === undefined) if_null = -1;
          let state = window.localStorage.getItem(key);
          return state == null ? if_null : parseInt(state);
        },
        setSessionState: function(key, value){
          window.sessionStorage.setItem(key, value.toString());
        },
        getSessionState: function(key, if_null){
          if(if_null === undefined) if_null = -1;
          let state = window.sessionStorage.getItem(key);
          return state == null ? if_null : parseInt(state);
        },
        isPremium: function(){
          return this.isLoggedIn() && parseInt(this.config.user.tier) > 0;
        },
        canInviteParent: function() {
          return this.isLoggedIn() && parseInt(this.config.user.total_seats) > parseInt(this.config.user.invited_seats);
        },
        isSubscribed: function(){
          return this.isPremium() && this.config.user.subscription;
        },
        dispatch: function(action, event){
          if(typeof gtag == 'function'){
            gtag('event', action, event);
          }else{
            console.log(`Unable to locate gtag. Would normally emit event: ${action} with params: %o`, event);
          }
        },
        ...mapActions(['setConfig']),
        ...mapActions('user', ['setUser', 'appendBoard', 'removeBoard', 'updateBoard']),
        ...mapGetters('user', ['isLoggedIn', 'isReadOnly']),
        ...mapActions('board', ['setBoard', 'getBoard', 'setBoardChoice', 'removeBoardChoice', 'setBoardPin', 'removeBoardPin', 'addMember', 'removeMember']),
        ...mapGetters('board', ['getMemberBoards', 'hasBoard']),
        ...mapActions('birthpro', ['setBirthPro']),
        ...mapGetters('birthpro', ['isBirthProLoggedIn'])
      },
      computed: {
        ...mapState(['user', 'board', 'menu', 'resources', 'options', 'config']),
        locked: function(){
          return !this.isLoggedIn() || (this.config.user && this.config.user.subscription && this.config.user.subscription.locked);
        },
        countries: function(){
          return {
            'United States': 'US',
            'Canada': 'CA',
            'Mexico': 'MX',
            '': 'DD',
            'Afghanistan': 'AF',
            'Åland Islands': 'AX',
            'Albania': 'AL',
            'Algeria': 'DZ',
            'American Samoa': 'AS',
            'Andorra': 'AD',
            'Angola': 'AO',
            'Anguilla': 'AI',
            'Antarctica': 'AQ',
            'Antigua and Barbuda': 'AG',
            'Argentina': 'AR',
            'Armenia': 'AM',
            'Aruba': 'AW',
            'Australia': 'AU',
            'Austria': 'AT',
            'Azerbaijan': 'AZ',
            'Bahamas': 'BS',
            'Bahrain': 'BH',
            'Bangladesh': 'BD',
            'Barbados': 'BB',
            'Belarus': 'BY',
            'Belgium': 'BE',
            'Belize': 'BZ',
            'Benin': 'BJ',
            'Bermuda': 'BM',
            'Bhutan': 'BT',
            'Bolivia, Plurinational State of': 'BO',
            'Bonaire, Sint Eustatius and Saba': 'BQ',
            'Bosnia and Herzegovina': 'BA',
            'Botswana': 'BW',
            'Bouvet Island': 'BV',
            'Brazil': 'BR',
            'British Indian Ocean Territory': 'IO',
            'Brunei Darussalam': 'BN',
            'Bulgaria': 'BG',
            'Burkina Faso': 'BF',
            'Burundi': 'BI',
            'Cambodia': 'KH',
            'Cameroon': 'CM',
            'Cape Verde': 'CV',
            'Cayman Islands': 'KY',
            'Central African Republic': 'CF',
            'Chad': 'TD',
            'Chile': 'CL',
            'China': 'CN',
            'Christmas Island': 'CX',
            'Cocos (Keeling) Islands': 'CC',
            'Colombia': 'CO',
            'Comoros': 'KM',
            'Congo': 'CG',
            'Congo, the Democratic Republic of the': 'CD',
            'Cook Islands': 'CK',
            'Costa Rica': 'CR',
            'Côte d\'Ivoire': 'CI',
            'Croatia': 'HR',
            'Cuba': 'CU',
            'Curaçao': 'CW',
            'Cyprus': 'CY',
            'Czech Republic': 'CZ',
            'Denmark': 'DK',
            'Djibouti': 'DJ',
            'Dominica': 'DM',
            'Dominican Republic': 'DO',
            'Ecuador': 'EC',
            'Egypt': 'EG',
            'El Salvador': 'SV',
            'Equatorial Guinea': 'GQ',
            'Eritrea': 'ER',
            'Estonia': 'EE',
            'Ethiopia': 'ET',
            'Falkland Islands (Malvinas': 'FK',
            'Faroe Islands': 'FO',
            'Fiji': 'FJ',
            'Finland': 'FI',
            'France': 'FR',
            'French Guiana': 'GF',
            'French Polynesia': 'PF',
            'French Southern Territories': 'TF',
            'Gabon': 'GA',
            'Gambia': 'GM',
            'Georgia': 'GE',
            'Germany': 'DE',
            'Ghana': 'GH',
            'Gibraltar': 'GI',
            'Greece': 'GR',
            'Greenland': 'GL',
            'Grenada': 'GD',
            'Guadeloupe': 'GP',
            'Guam': 'GU',
            'Guatemala': 'GT',
            'Guernsey': 'GG',
            'Guinea': 'GN',
            'Guinea-Bissau': 'GW',
            'Guyana': 'GY',
            'Haiti': 'HT',
            'Heard Island and McDonald Islands': 'HM',
            'Holy See (Vatican City State': 'VA',
            'Honduras': 'HN',
            'Hong Kong': 'HK',
            'Hungary': 'HU',
            'Iceland': 'IS',
            'India': 'IN',
            'Indonesia': 'ID',
            'Iran, Islamic Republic of': 'IR',
            'Iraq': 'IQ',
            'Ireland': 'IE',
            'Isle of Man': 'IM',
            'Israel': 'IL',
            'Italy': 'IT',
            'Jamaica': 'JM',
            'Japan': 'JP',
            'Jersey': 'JE',
            'Jordan': 'JO',
            'Kazakhstan': 'KZ',
            'Kenya': 'KE',
            'Kiribati': 'KI',
            'Korea, Democratic People\'s Republic of': 'KP',
            'Korea, Republic of': 'KR',
            'Kuwait': 'KW',
            'Kyrgyzstan': 'KG',
            'Lao People\'s Democratic Republic': 'LA',
            'Latvia': 'LV',
            'Lebanon': 'LB',
            'Lesotho': 'LS',
            'Liberia': 'LR',
            'Libya': 'LY',
            'Liechtenstein': 'LI',
            'Lithuania': 'LT',
            'Luxembourg': 'LU',
            'Macao': 'MO',
            'Macedonia, the former Yugoslav Republic of': 'MK',
            'Madagascar': 'MG',
            'Malawi': 'MW',
            'Malaysia': 'MY',
            'Maldives': 'MV',
            'Mali': 'ML',
            'Malta': 'MT',
            'Marshall Islands': 'MH',
            'Martinique': 'MQ',
            'Mauritania': 'MR',
            'Mauritius': 'MU',
            'Mayotte': 'YT',
            'Micronesia, Federated States of': 'FM',
            'Moldova, Republic of': 'MD',
            'Monaco': 'MC',
            'Mongolia': 'MN',
            'Montenegro': 'ME',
            'Montserrat': 'MS',
            'Morocco': 'MA',
            'Mozambique': 'MZ',
            'Myanmar': 'MM',
            'Namibia': 'NA',
            'Nauru': 'NR',
            'Nepal': 'NP',
            'Netherlands': 'NL',
            'New Caledonia': 'NC',
            'New Zealand': 'NZ',
            'Nicaragua': 'NI',
            'Niger': 'NE',
            'Nigeria': 'NG',
            'Niue': 'NU',
            'Norfolk Island': 'NF',
            'Northern Mariana Islands': 'MP',
            'Norway': 'NO',
            'Oman': 'OM',
            'Pakistan': 'PK',
            'Palau': 'PW',
            'Palestinian Territory, Occupied': 'PS',
            'Panama': 'PA',
            'Papua New Guinea': 'PG',
            'Paraguay': 'PY',
            'Peru': 'PE',
            'Philippines': 'PH',
            'Pitcairn': 'PN',
            'Poland': 'PL',
            'Portugal': 'PT',
            'Puerto Rico': 'PR',
            'Qatar': 'QA',
            'Réunion': 'RE',
            'Romania': 'RO',
            'Russian Federation': 'RU',
            'Rwanda': 'RW',
            'Saint Barthélemy': 'BL',
            'Saint Helena, Ascension and Tristan da Cunha': 'SH',
            'Saint Kitts and Nevis': 'KN',
            'Saint Lucia': 'LC',
            'Saint Martin (French part': 'MF',
            'Saint Pierre and Miquelon': 'PM',
            'Saint Vincent and the Grenadines': 'VC',
            'Samoa': 'WS',
            'San Marino': 'SM',
            'Sao Tome and Principe': 'ST',
            'Saudi Arabia': 'SA',
            'Senegal': 'SN',
            'Serbia': 'RS',
            'Seychelles': 'SC',
            'Sierra Leone': 'SL',
            'Singapore': 'SG',
            'Sint Maarten (Dutch part': 'SX',
            'Slovakia': 'SK',
            'Slovenia': 'SI',
            'Solomon Islands': 'SB',
            'Somalia': 'SO',
            'South Africa': 'ZA',
            'South Georgia and the South Sandwich Islands': 'GS',
            'South Sudan': 'SS',
            'Spain': 'ES',
            'Sri Lanka': 'LK',
            'Sudan': 'SD',
            'Suriname': 'SR',
            'Svalbard and Jan Mayen': 'SJ',
            'Swaziland': 'SZ',
            'Sweden': 'SE',
            'Switzerland': 'CH',
            'Syrian Arab Republic': 'SY',
            'Taiwan, Province of China': 'TW',
            'Tajikistan': 'TJ',
            'Tanzania, United Republic of': 'TZ',
            'Thailand': 'TH',
            'Timor-Leste': 'TL',
            'Togo': 'TG',
            'Tokelau': 'TK',
            'Tonga': 'TO',
            'Trinidad and Tobago': 'TT',
            'Tunisia': 'TN',
            'Turkey': 'TR',
            'Turkmenistan': 'TM',
            'Turks and Caicos Islands': 'TC',
            'Tuvalu': 'TV',
            'Uganda': 'UG',
            'Ukraine': 'UA',
            'United Arab Emirates': 'AE',
            'United Kingdom': 'GB',
            'United States Minor Outlying Islands': 'UM',
            'Uruguay': 'UY',
            'Uzbekistan': 'UZ',
            'Vanuatu': 'VU',
            'Venezuela, Bolivarian Republic of': 'VE',
            'Viet Nam': 'VN',
            'Virgin Islands, British': 'VG',
            'Virgin Islands, U.S': 'VI',
            'Wallis and Futuna': 'WF',
            'Western Sahara': 'EH',
            'Yemen': 'YE',
            'Zambia': 'ZM',
            'Zimbabwe': 'ZW'
          };
        },
        us_states: function(){
          return {
            'Alabama': 'AL',
            'Alaska': 'AK',
            'Arizona': 'AZ',
            'Arkansas': 'AR',
            'California': 'CA',
            'Colorado': 'CO',
            'Connecticut': 'CT',
            'Delaware': 'DE',
            'District Of Columbia': 'DC',
            'Florida': 'FL',
            'Georgia': 'GA',
            'Hawaii': 'HI',
            'Idaho': 'ID',
            'Illinois': 'IL',
            'Indiana': 'IN',
            'Iowa': 'IA',
            'Kansas': 'KS',
            'Kentucky': 'KY',
            'Louisiana': 'LA',
            'Maine': 'ME',
            'Maryland': 'MD',
            'Massachusetts': 'MA',
            'Michigan': 'MI',
            'Minnesota': 'MN',
            'Mississippi': 'MS',
            'Missouri': 'MO',
            'Montana': 'MT',
            'Nebraska': 'NE',
            'Nevada': 'NV',
            'New Hampshire': 'NH',
            'New Jersey': 'NJ',
            'New Mexico': 'NM',
            'New York': 'NY',
            'North Carolina': 'NC',
            'North Dakota': 'ND',
            'Ohio': 'OH',
            'Oklahoma': 'OK',
            'Oregon': 'OR',
            'Pennsylvania': 'PA',
            'Rhode Island': 'RI',
            'South Carolina': 'SC',
            'South Dakota': 'SD',
            'Tennessee': 'TN',
            'Texas': 'TX',
            'Utah': 'UT',
            'Vermont': 'VT',
            'Virginia': 'VA',
            'Washington': 'WA',
            'West Virginia': 'WV',
            'Wisconsin': 'WI',
            'Wyoming': 'WY'
          };
        },
        mx_states: function(){
          return {
            'Distrito Federal': 'DIF',
            'Aguascalientes': 'AGS',
            'Baja California': 'BCN',
            'Baja California Sur': 'BCS',
            'Campeche': 'CAM',
            'Chiapas': 'CHP',
            'Chihuahua': 'CHI',
            'Coahuila': 'COA',
            'Colima': 'COL',
            'Durango': 'DUR',
            'Guanajuato': 'GTO',
            'Guerrero': 'GRO',
            'Hidalgo': 'HGO',
            'Jalisco': 'JAL',
            'M&eacute;xico': 'MEX',
            'Michoac&aacute;n': 'MIC',
            'Morelos': 'MOR',
            'Nayarit': 'NAY',
            'Nuevo Le&oacute;n': 'NLE',
            'Oaxaca': 'OAX',
            'Puebla': 'PUE',
            'Quer&eacute;taro': 'QRO',
            'Quintana Roo': 'ROO',
            'San Luis Potos&iacute;': 'SLP',
            'Sinaloa': 'SIN',
            'Sonora': 'SON',
            'Tabasco': 'TAB',
            'Tamaulipas': 'TAM',
            'Tlaxcala': 'TLX',
            'Veracruz': 'VER',
            'Yucat&aacute;n': 'YUC',
            'Zacatecas': 'ZAC'
          };
        },
        provinces: function(){
          return {
            'Alberta': 'AB',
            'British Columbia': 'BC',
            'Manitoba': 'MB',
            'New Brunswick': 'NB',
            'Newfoundland and Labrador': 'NL',
            'Nova Scotia': 'NS',
            'Ontario': 'ON',
            'Prince Edward Island': 'PE',
            'Quebec': 'QC',
            'Saskatchewan': 'SK',
            'Northwest Territories': 'NT',
            'Nunavut': 'NU',
            'Yukon': 'YT'
          };
        }
      }
    });
  }
};

export default MotherboardPlugin;
