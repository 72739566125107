// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/svgs/question.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".v-help[data-v-4a42c490]{display:flex;height:40px;background-color:#c30252;border-top-left-radius:3px;border-top-right-radius:3px;cursor:pointer}.v-help>.question[data-v-4a42c490]{flex:0 0 40px;width:40px;height:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:22px 22px;background-position:center center;background-repeat:no-repeat;background-color:#91013d;border-top-left-radius:3px}.v-help>.label[data-v-4a42c490]{flex:1 1 auto;font-size:1.8rem;font-weight:bold;color:#fff;line-height:40px;padding:0 2rem}\n", ""]);
// Exports
module.exports = exports;
