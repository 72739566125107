// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Import stylesheet
import 'assets/stylesheets/application.scss';

// UJS for handling ajax responses
import 'jquery-ujs';

import Parallax from 'jquery-parallax.js';
import { DialogsWrapper } from 'vue-modal-dialogs';

Vue.config.devtools = true;
Vue.config.debug    = true;
Vue.config.silent   = false;

/*
if(process.env.NODE_ENV == 'production'){
  Vue.config.devtools = false;
  Vue.config.debug    = false;
  Vue.config.silent   = true;
}
*/

import VMotherboard from '@components/motherboard/motherboard.vue';
import VFooter from '@components/footer/footer.vue';
import MotherboardPlugin from '@plugins/motherboard';

Vue.use(MotherboardPlugin);

import store from '@store/index';

window.$ = $;
window.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#app',
    store,
    components: {
      VMotherboard,
      VFooter,
      DialogsWrapper,
      VLogo:      () => import('@components/logo/logo.vue'),
      VAccordion: () => import('@components/accordion/accordion.vue'),
      VFlash:     () => import('@components/flash/flash.vue'),
      VTextArea:  () => import('@components/textarea/textarea.vue'),
      VText:      () => import('@components/text/text.vue'),
      VRadio:     () => import('@components/radio/radio.vue'),
      VButton:    () => import('@components/button/button.vue'),
      VSelect:    () => import('@components/select/select.vue'),
      VNav:       () => import('@components/nav/nav.vue'),
      VNavItem:   () => import('@components/navitem/navitem.vue'),
      VAction:    () => import('@components/action/action.vue'),
      VAvatar:    () => import('@components/avatar/avatar.vue'),
      VMenu:      () => import('@components/menu/menu.vue'),
      VRegister:  () => import('@components/register/register.vue'),
      VCollaboratorNew: () => import ('@components/collaborator_register/new.vue'),
      VGoodbye:   () => import('@components/goodbye/goodbye.vue'),
      VBoard:     () => import('@components/board/board.vue'),
      VResource:  () => import('@components/resource/resource.vue'),
      VOverview:  () => import('@components/overview/overview.vue'),
      VTools:  () => import('@components/tools/index.vue'),
      VTimeline:  () => import('@components/timeline/timeline.vue'),
      VAccount:   () => import('@components/account/account.vue'),
      VTopic:     () => import('@components/topic/topic.vue'),
      VSeatsTable: () => import('@components/parents/index/table.vue'),
      VNewInvoice: () => import('@components/user_invoices/invoices/new/new.vue'),
      VBirthproRegiter: () => import('@components/birthpro/register/register.vue')
    }
  });
});