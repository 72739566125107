import Vuex from 'vuex';

import '@includes/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
  },
  mutations: {
    ASSIGN_BIRTH_PRO(state, user){
      ['id', 'first_name', 'last_name', 'email'].forEach((key) => {
        if(user[key] !== undefined) Vue.set(state, key, user[key]);
      });
    },
  },
  actions: {
    setBirthPro({ commit }, user){
      commit('ASSIGN_BIRTH_PRO', user);
    },
  },
  getters: {
    isBirthProLoggedIn(state){
      return state.id > 0;
    },
  },
  strict: process.env.NODE_ENV !== 'production'
};
