import VButton from '@components/button/button.vue';
import VSelect from '@components/select/select.vue';
import VText from '@components/text/text.vue';
import VFlash from '@components/flash/flash.vue';
import VueSlideUpDown from 'vue-slide-up-down';

import fecha from 'fecha';

$.fn.serializeObject = function(){
  var o = {};
  var a = this.serializeArray();
  $.each(a, function(){
    if(o[this.name]){
      if (!o[this.name].push){
        o[this.name] = [o[this.name]];
      }
      o[this.name].push(this.value || '');
    }else{
      o[this.name] = this.value || '';
    }
  });
  return o;
};

export default {
  props: {
    prompt: {
      type: Object,
      default: () => {}
    },
    sources: {
      type: Array,
      default: () => []
    }
  },
  components: { VButton, VSelect, VText, VFlash, VueSlideUpDown },
  created: function(){
    this.stripe = Stripe(this.config.stripe.publishable_key);
  },
  mounted: function(){
    var elements = this.stripe.elements({
      fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans' }],
      locale: 'auto'
    });

    var elementStyles = {
      base: {
        color: '#333c4e',
        fontWeight: 400,
        fontFamily: 'Open Sans, Helvetica, Arial, sans-serif',
        fontSize: '15px',
        fontSmoothing: 'antialiased',
        lineHeight: '41px',

        '::placeholder': {
          color: '#d3d3d3',
        },
        ':-webkit-autofill': {
          color: '#e39f48',
        },
      },
      invalid: {
        color: '#E25950',

        '::placeholder': {
          color: '#d3d3d3',
        },
      },
    };

    var elementClasses = { focus: 'focused', empty: 'empty', invalid: 'invalid' };

    var cardNumber = elements.create('cardNumber', { style: elementStyles, classes: elementClasses });
    cardNumber.mount('#card_number');

    var cardExpiry = elements.create('cardExpiry', { style: elementStyles, classes: elementClasses });
    cardExpiry.mount('#card_expiration');

    var cardCvc = elements.create('cardCvc', { style: elementStyles, classes: elementClasses });
    cardCvc.mount('#card_cvc');

    this.elements.push(cardNumber, cardExpiry, cardCvc);

    $(this.$el).find('.billing-source select').trigger('change');
  },
  methods: {
    onSubmit: function(){
      if(this.payment == '0'){
        this.stripe.createToken(this.elements[0], this.getAddressInfo()).then((result) => {
          if(result.error){
            $(this.$el).find('.v-button.loading').get(0).__vue__.loading = false;
            if(result.error.message){
              this.flashAt(this.$el).add({ alert: result.error.message });
            }
          }else{
            this.onRenew({ token: result.token.id, coupon: this.coupon });
          }
        });
      }else{
        this.onRenew({ token: this.payment, coupon: this.coupon });
      }
    },
    onRenew: function(params){
      Axios.post('/renewals.json', params)
        .then((response) => {
          this.setConfig(response.data.config);
          this.$close(response.data.sources);
          this.dispatch('renew', { event_category: 'ecommerce', event_label: 'success', value: this.user.id || 0 });
        })
        .catch((error) => {
          $(this.$el).find('.v-button.loading').get(0).__vue__.loading = false;
          this.flashAt(this.$el).add(error.response.data.flash);
          this.dispatch('renew', { event_category: 'ecommerce', event_label: 'failure', value: this.user.id || 0 });
        });
    },
    getAddressInfo: function(){
      let info = $('.billing-form').serializeObject();
      info['name'] = `${info['first_name']} ${info['last_name']}`;
      delete info['first_name'];
      delete info['last_name'];
      return info;
    },
    getSourceStatus: function(source){
      if(source.expired || source.last_four == '0077'){
        return 'Expired';
      }else{
        return `Expires: ${source.expiration}`;
      }
    },
    getSourceLabel: function(source){
      return `
      <div class="source-option">
        <div class="source-brand">${source.brand}</div>
        <div class="source-last4">xxxx-${source.last_four}</div>
        <div class="source-status" class="${source.expired ? 'is-expired' : ''}">${this.getSourceStatus(source)}</div>
      </div>`;
    }
  },
  computed: {
    plan: function(){
      switch(this.config.user.tier){
        case 1:
          return 'Tier 1';
        case 2:
          return 'Tier 2';
        default:
          return 'Guest';
      }
    },
    expires: function(){
      let expiration = new Date(this.config.user.subscription.period_end);
      return fecha.format(expiration, 'MMMM D, YYYY');
    },
    renewal: function(){
      let expiration = new Date();
      expiration.setFullYear(expiration.getFullYear() + 1);
      return fecha.format(expiration, 'MMMM D, YYYY');
    }
  },
  data: function(){
    return {
      stripe: {},
      elements: [],
      payment: '0',
      coupon: null
    }
  }
};
