import Axios from 'axios';

export default {
  name: 'v-button',
  props: {
    href: String,
    method: String,
    topic_id: String,
    type: {
      type: String,
      default: '',
      validator: function(value){
        return ['submit', 'button'].indexOf(value) !== -1;
      }
    },
    option: {
      type: Object,
      required: false
    },
    navigable: {
      type: [String, Boolean],
      default: false,
    },
    toggler: {
      type: String
    },
    loader: {
      type: Boolean,
      default: false
    },
    load: {
      type: Boolean,
      default: false
    },
    clickOnce: {
      type: Boolean,
      default: false
    },
    toggled: {
      type: Boolean,
      default: false
    },
    persist: {
      type: Boolean,
      default: false
    }
  },
  mounted: function(){
    this.loading = this.load;

    if(this.isNavigable()){
      this.active = this.getNavigableActive();
    } else {
      this.active = this.$el.hasAttribute('active');
    }

    if(this.loader){
      Axios.interceptors.response.use((response) => {
        if(response.status == 200 && this.persist){
          return response;
        }
        this.loading = false;
        return response;
      });
    }

    if(this.active && this.$parent){
      this.$parent.$emit('accordion:open');
    }

//    let form = $(this.$el).closest('form');
//    if(this.type == 'submit' && this.loader && form.length && form.data('remote') === true){
//      form.on('ajax:send', () => {
//        this.loading = true;
//      });
//
//      form.on('ajax:complete', () => {
//        this.loading = false;
//      });
//    }
  },
  methods: {
    onClick: function(){
      if(this.loader && this.clickOnce){
        this.loading = true;
      }
    },
    isNavigable: function(){
      return !!this.href && !!this.navigable;
    },
    getNavigableActive: function(){
      if(this.navigable == 'exact'){
        let target = this.href.substr(1).split('/').slice(0, 3);
        let current = window.location.pathname.substr(1).split('/').slice(0, 3);
        return target.join('/') == current.join('/');
      }
      return new RegExp('^' + this.href).test(window.location.pathname);
    }
  },
  computed: {
    isLink: function(){
      return !!this.href && !this.type;
    },
    isToggled: function(){
      if(this.toggler){
        if(typeof this.$parent[this.toggler] == 'function') return this.$parent[this.toggler]();
        return this.$parent[this.toggler];
      }
      return this.toggled || (this.option && this.topic_id && this.motherboard.choices[this.topic_id] == this.option.id);
    }
  },
  data: function(){
    return {
      active: false,
      loading: false
    };
  },
};
