export default {
  name: 'v-flash',
  props: {
    flash: {
      type: Object,
      default: () => {},
    },
    dismiss: {
      type: Boolean,
      default: true
    },
    forgettable: {
      type: String,
      default: ''
    }
  },
  created: function(){
    this.add(this.flash, true);
  },
  watch: {
    flashes: function(new_flash){
      this.$nextTick(() => {
        $(this.$el).find('.flash:not(:visible):not(.instant)').slideDown(200);
        $(this.$el).find('.flash.instant:not(:visible)').show();
      });
    }
  },
  methods: {
    add: function(flash, instant){
      let index;

      for(var type in flash){
        while((index = this.flashes.findIndex(f => f.type == type)) > -1){
          this.flashes.splice(index, 1);
        }

        let messages = [flash[type]].reduce((acc, val) => acc.concat(val), []).filter((message) => {
          return !!message;
        });

        if(messages.length){
          this.flashes.push({ type: type, messages: messages, instant: !!instant });
        }
      }
    },
    close: function(event, index){
      $(event.target).closest('.flash').slideUp(200, () => {
        $(event.target).closest('.flash').remove();
        this.flashes.splice(index, 1);
      });

      if(this.forgettable){
        this.setState(`flash:${this.forgettable}`, 1);
      }
    },
    clear: function(){
      $(this.$el).find('.flash').slideUp(200, () => {
        $(this).remove();
        this.flashes = [];
      });
    },
    isDismissable: function(){
      return this.dismiss;
    },
    isForgotten: function(){
      return !!this.forgettable && this.getState(`flash:${this.forgettable}`) == 1;
    }
  },
  data: function() {
    return {
      flashes: []
    };
  },
};
