import Renew from '@components/account/dialogs/renew/renew.vue';
import { create } from 'vue-modal-dialogs';

export default {
  name: 'v-motherboard',
  props: {
    _board: {
      type: Object,
      required: true
    },
    _user: {
      type: Object,
      required: true
    },
    _options: {
      type: Array,
      required: true
    },
    _menu: {
      type: Array,
      required: true
    },
    _resources: {
      type: Array,
      required: true
    },
    _config: {
      type: Object,
      default: () => {}
    },
    _plans: {
      type: Object,
      default: () => {}
    }
  },
  created: function(){
    this.setUser(this._user);
    this.setBoard(this._board);
    this.setConfig(this._config);

    this.$store.commit('ASSIGN_MENU', this._menu);
    this.$store.commit('ASSIGN_OPTIONS', this._options);
    this.$store.commit('ASSIGN_RESOURCES', this._resources);
  },
  mounted: function(){
    if(this.locked && this.isLoggedIn()){
      if(!this.getSessionState('renewal:notified', 0)){
        Axios.get('/sources.json').then((response) => {
          this.onRenew(response.data);
          this.setSessionState('renewal:notified', 1);
        });
      }
    }
  },
  methods: {
    onRenew: async function(current_sources){
      let renew = create(Renew);
      let sources = await renew({ sources: current_sources }).transition();
      if($('.v-account').length && sources && sources.length){
        $('.v-account').get(0).__vue__.sources = sources;
      }
    }
  }
};
