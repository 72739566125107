import Vuex from 'vuex';

import '@includes/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    id: 0,
    title: '',
    birth_model: '',
    user: {},
    pins: [],
    choices: {},
    team: [],
    color: ''
  },
  mutations: {
    ASSIGN_BOARD(state, board){
      if(!board) board = {};
      ['id', 'title', 'birth_model', 'user', 'pins', 'choices', 'team', 'color'].forEach((key) => {
        if(board[key] !== undefined) Vue.set(state, key, board[key]);
      });
    },
    UPDATE_CHOICE(state, payload){
      Vue.set(state.choices, payload.topic_id, payload.option_id);
    },
    DELETE_CHOICE(state, topic_id){
      Vue.delete(state.choices, topic_id);
    },
    UPDATE_PIN(state, topic_id){
      state.pins.push(topic_id);
    },
    DELETE_PIN(state, topic_id){
      let index = state.pins.indexOf(topic_id);
      if(index > -1) state.pins.splice(index, 1);
    },
    ADD_MEMBER(state, member){
      state.team.push(member);
    },
    DELETE_MEMBER(state, member_id){
      let index = state.team.findIndex(member => member.id == member_id);
      if(index > -1) state.team.splice(index, 1);
    }
  },
  actions: {
    setBoard({ commit }, board){
      commit('ASSIGN_BOARD', board);
    },
    getBoard({ commit }, id){
      return Axios.get(`/boards/${id}.json`)
        .then((response) => {
          commit('ASSIGN_BOARD', response.data);
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    setBoardChoice({ commit }, payload){
      Axios.post('/choices', payload).then(() => {
        commit('UPDATE_CHOICE', payload);
      });
    },
    removeBoardChoice({ commit }, topic_id){
      Axios.delete(`/choices/${topic_id}`).then(() => {
        commit('DELETE_CHOICE', topic_id);
      });
    },
    setBoardPin({ commit }, topic_id){
      Axios.post('/pins', { topic_id: topic_id }).then((response) => {
        commit('UPDATE_PIN', topic_id);
      });
    },
    removeBoardPin({ commit }, topic_id){
      Axios.delete(`/pins/${topic_id}`).then(() => {
        commit('DELETE_PIN', topic_id);
      });
    },
    addMember({ commit }, member){
      commit('ADD_MEMBER', member);
    },
    removeMember({ commit }, member_id){
      Axios.delete(`/members/${member_id}`).then(() => {
        commit('DELETE_MEMBER', member_id);
      });
    }
  },
  getters: {
    getMemberBoards(state){
      (id) => {
        return Axios.get(`/users/${id}/boards.json`)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            return error;
          });
      }
    },
    hasBoard(state, getters, rootState){
      return parseInt(rootState.board.id || 0) > 0;
    }
  },
  strict: process.env.NODE_ENV !== 'production'
};
