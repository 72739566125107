import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';

export default {
  mounted: function(){
    // Initialize the slider
    $(this.$el).find('.slides').slick({
      mobileFirst: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      swipeToSlide: true,
      infinite: true,
      prevArrow: '',
      nextArrow: '',
      dots: true
    });

    // Allow clicking outside the modal to close
    $(document).on('click', (event) => {
      if(!$(event.target).closest('.dialog').length && $(event.target).closest('.wrapper').length){
        this.$close(false);
      }
    });

    // Update the user flag indicating that the tutorial modal has been shown, so we don't show it again
    Axios.patch(`/users/${this.user.id}.json`, { user: { guided: true } });
    this.setState('tutorial:shown', 1);
  }
};
