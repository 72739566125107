// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/svgs/close.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".help-dialog[data-v-3d3738fe]{width:auto;max-width:1100px;padding:2rem}.help-dialog>.dialog-content[data-v-3d3738fe]{padding:0}.help-dialog>.dialog-content>.close[data-v-3d3738fe]{position:absolute;z-index:1;top:2rem;right:2rem;display:none;width:40px;height:40px;padding:0;cursor:pointer;border:0;border-bottom-left-radius:3px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:rgba(0,0,0,0.7);background-repeat:no-repeat;background-position:center center;background-size:20px 20px}.slides[data-v-3d3738fe] .slick-slide{margin:0}.slides[data-v-3d3738fe] .slick-dots{display:flex;width:100%;margin-top:1rem;justify-content:center;align-content:center}.slides[data-v-3d3738fe] .slick-dots>li{display:flex;align-content:center}.slides[data-v-3d3738fe] .slick-dots>li.slick-active>button{background-color:#0089df}.slides[data-v-3d3738fe] .slick-dots>li>button{font-size:0;width:40px;height:12px;margin:0 .5rem;padding:0;cursor:pointer;transition:background-color 250ms ease-out;border:2px solid #0089df;border-radius:12px;background-color:transparent}.slides[data-v-3d3738fe] .slick-dots>li>button::-moz-focus-inner{border:0}.slides[data-v-3d3738fe] .slide{overflow:hidden}.slides[data-v-3d3738fe] .slide>.image{background-repeat:no-repeat;background-position:center center;background-size:contain}.slides[data-v-3d3738fe] .slide>.image:after{display:block;padding-bottom:46.363636%;content:''}.slides[data-v-3d3738fe] .slide>.copy{line-height:1.4;margin-top:1rem}.slide-heading[data-v-3d3738fe]{font-size:2.2rem;font-weight:bold}@media only screen and (max-width: 1150px){.help-dialog[data-v-3d3738fe]{max-width:85%;margin:0 auto}.help-dialog>.dialog-content>.close[data-v-3d3738fe]{display:block}}\n", ""]);
// Exports
module.exports = exports;
