import Vuex from 'vuex';

import '@includes/axios';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    character: '',
    thumb: '',
    avatar: '',
    boards: []
  },
  mutations: {
    ASSIGN_USER(state, user){
      ['id', 'first_name', 'last_name', 'email', 'character', 'thumb', 'avatar', 'boards'].forEach((key) => {
        if(user[key] !== undefined) Vue.set(state, key, user[key]);
      });
    },
    APPEND_BOARD(state, board){
      state.boards.push(board);
    },
    REMOVE_BOARD(state, board_id){
      state.boards = state.boards.filter((board) => {
        return board.id != board_id;
      });
    },
    UPDATE_BOARD(state, payload){
      let index = state.boards.findIndex(board => board.id == payload.id);

      ['title', 'color'].forEach((key) => {
        if(payload[key] !== undefined) Vue.set(state.boards[index], key, payload[key]);
      });
    }
  },
  actions: {
    setUser({ commit }, user){
      commit('ASSIGN_USER', user);
    },
    appendBoard({ commit }, board){
      commit('APPEND_BOARD', board);
    },
    removeBoard({ commit }, board_id){
      commit('REMOVE_BOARD', board_id);
    },
    updateBoard({ commit, rootState }, payload){
      commit('UPDATE_BOARD', payload);

      if(rootState.board.id == payload.id){
        commit('UPDATE_BOARD', payload, { root: true });
      }
    }
  },
  getters: {
    isLoggedIn(state){
      return state.id > 0;
    },
    isReadOnly(state, getters, rootState){
      return rootState.config.user && (rootState.config.user.tier == 0 || state.id != rootState.board.user.id || (rootState.config.user.subscription && rootState.config.user.subscription.locked)) && parseInt(rootState.board.id || 0) > 0;
    }
  },
  strict: process.env.NODE_ENV !== 'production'
};
