export default {
  name: 'v-text',
  props: {
    name: {
      type: String,
      required: true,
    },
    type: String,
    autocomplete: {
      type: [String, Boolean],
      default: 'on',
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  mounted: function(){
    this.value = this.$el.getAttribute('value') || '';
  },
  watch: {
    value: function(new_val){
      this.$emit('input', new_val);
    }
  },
  methods: {
    getId: function(suffix) {
      return [this.name.replace(/[^a-z0-9_]+/gi, '_'), suffix].map(function(val){
        if(val) return val;
      }).join('_').replace(/^_+|_*$/g, '');
    },
    onKeyUp: function(event){
      this.$emit('keyup', event);
    },
    onKeyDown: function(event){
      this.$emit('keydown', event);
    }
  },
  computed: {
    hasInput: function() {
      return !(this.value.replace(/^\s+|\s+$/) == '') || !(this.placeholder.replace(/^\s+|\s+$/) == '');
    },
    id: function() {
      return this.getId();
    }
  },
  data: function(){
    return {
      value: ''
    };
  }
};
