import '@vendor/jquery.selectric';
import 'selectric/src/selectric.scss';
import 'selectric/src/plugins/jquery.selectric.placeholder';

export default {
  name: 'v-select',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    multiple: {
      type: String
    },
    placeholder: {
      type: [String, Boolean],
      default: false
    }
  },
  mounted: function(){
    let self = this;

    $(this.$refs.select).selectric({
        onBeforeInit: function(select, selectric){
          if(self.placeholder !== false){
            $(select).data('initial_value', $(select).find('option[selected]').attr('value'));
            $(select).data('initial_label', $(select).find('option[selected]').html());
          }
        },
        onInit: function(select, selectric) {
          if($(select).data('initial_label') && self.placeholder !== false){
            selectric.$element.attr('placeholder', $(select).data('initial_label'));
          }
        },
        onOpen: function(select, selectric){
          if($(select).data('initial_value') == undefined && self.placeholder !== false){
            selectric.state.highlightedIdx = -1;
            selectric.state.selectedIdx = -1;
            selectric.state.currValue = -1;
            $(selectric.elements.items).find('li').removeClass('selected highlighted');
            $(select).data('initial_value', 1);
          }
        },
        labelBuilder: function(item){
          let $option = $(item.element);
          if($option.data('label')){
            return $option.data('label');
          }else{
            return $(item.element).text();
          }
        }
      })
      .on('change', () => {
        this.$emit('change', $(this.$el).find('select').val());
        this.$emit('input', $(this.$el).find('select').val());
      });

    if(this.placeholder !== false){
      $(this.$refs.select).selectricPlaceholder({ placeholderOnOpen: false });
    }

  },
  methods: {
    hasLabel: function(){
      return !!this.label;
    }
  }
};
