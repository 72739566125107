import Vuex from 'vuex';

import User from '@store/modules/user';
import Board from '@store/modules/board';
import BirthPro from '@store/modules/birthpro';

Vue.use(Vuex);

Vue.config.devtools = true;

export default new Vuex.Store({
  state: {
    user: {},
    board: {},
    menu: [],
    options: [],
    config: {},
    resources: []
  },
  mutations: {
    ASSIGN_MENU(state, menu){
      state.menu = menu;
    },
    ASSIGN_RESOURCES(state, resources){
      state.resources = resources;
    },
    ASSIGN_OPTIONS(state, options){
      state.options = options;
    },
    ASSIGN_CONFIG(state, config){
      state.config = config;
    },
    UPDATE_MENU(state, payload){
      state.menu = state.menu.map((category) => {
        if(payload[category.id]){
          ['birth_model', 'max', 'title', 'topics'].forEach((key) => {
            if(payload[category.id][key] !== undefined) category[key] = payload[category.id][key];
          });
        }
        return category;
      });
    },
    UPDATE_BOARD(state, payload){
      ['title', 'color'].forEach((key) => {
        if(payload[key] !== undefined) Vue.set(state.board, key, payload[key]);
      });
    }
  },
  modules: {
    user: User,
    board: Board,
    birthpro: BirthPro
  },
  actions: {
    setConfig({ commit }, config){
      commit('ASSIGN_CONFIG', config);
    }
  },
  getters: {
    user(state){
      return state.user;
    },
    board(state){
      return state.board;
    },
    menu(state){
      return state.menu;
    },
    options(state){
      return state.options;
    },
    config(state){
      return state.config;
    }
  },
  strict: process.env.NODE_ENV !== 'production'
});
