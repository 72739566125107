export default {
  name: 'v-help',
  methods: {
    onOpenHelp: function(){
      if(this.isLoggedIn()){
        zE.identify({
          name: [this.user.first_name, this.user.last_name].join(' '),
          email: this.user.email
        });
      }
      zE.activate();
    }
  }
};
