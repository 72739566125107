export default (function(){
  Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

  let objectToFormData = function(obj, form, namespace){
    let fd = form || new FormData();
    let formKey;

    for(let property in obj){
      if(obj.hasOwnProperty(property)){
        
        if(namespace){
          formKey = namespace + '[' + property + ']';
        }else{
          formKey = property;
        }
       
        // if the property is an object, but not a File,
        // use recursivity.
        if(typeof obj[property] === 'object' && !(obj[property] instanceof File)){
          objectToFormData(obj[property], fd, property);
        }else{
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }
    return fd;
  };

  Axios.interceptors.request.use((config) => {
    jQuery.active = 1;
    config.transformRequest = [
      (data, headers) => {
        data = data || {};
        let formData = objectToFormData(data);
        formData.append('authenticity_token', $('meta[name="csrf-token"]').attr('content'));
        return formData;
      }
    ];
    return config;
  });

  Axios.interceptors.response.use((response) => {
    // Catch 403 forbidden error responses and show a message, if one is not defined already
    if(response.status == 403 && !response.data){
      response.data = { flash: { alert: 'You do not have permission to perform this action.' } };
    }

    if(response.data){
      let flash = $(document).find('.v-flash').get(0);

      if(flash && response.data.flash){
        flash.__vue__.add(response.data.flash);
      }
    }
    jQuery.active = 0;
    return response;
  });

  $(document).on('ajax:before', 'form', (event, data) => {
    jQuery.active = 1;
  });

  $(document).on('ajax:complete', 'form', (event, data) => {
    jQuery.active = 0;
  });

  $(document).on('ajax:success', 'form', (event, data) => {
    let form = $(event.target);
    let flash = $('body .v-flash').get(0);

    if(flash && data.flash){
      flash.__vue__.add(data.flash);
    }

    form.trigger('reset');
  });

  $(document).on('ajax:error', 'form', (event, xhr, status, error) => {
    let data = xhr.responseJSON || {};
    let form = $(event.target);
    let flash = form.find('.v-flash').get(0) || form.prevAll('.v-flash').get(0) || form.closest('.v-flash').get(0);

    // Catch 403 forbidden error responses and show a message, if one is not defined already
    if(xhr.status == 403 && !data.flash){
      data.flash = { alert: 'You do not have permission to perform this action.' };
    }

    if(flash && data && data.flash){
      flash.__vue__.add(data.flash);
    }
  });
})();
